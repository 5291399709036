<template>
  <div>
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">

      <el-form-item label="配置日期">
        <el-date-picker v-model="dataForm.createTimeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          align="right">
        </el-date-picker>
      </el-form-item>


      <el-form-item label="干预类型">
        <el-select v-model="dataForm.types" clearable multiple placeholder="干预类型">
          <el-option v-for="item in typeOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="生效展位">
        <el-select v-model="dataForm.categoryIds" clearable multiple placeholder="生效展位">
          <el-option v-for="item in allCategoryList" :key="item.id" :label="item.description" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="任务状态">
        <el-select v-model="dataForm.statusList" clearable multiple placeholder="任务状态">
          <el-option v-for="item in statusOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>



      <el-form-item label="配置人">
        <el-select v-model="dataForm.updatePersons" clearable multiple placeholder="配置人">
          <el-option v-for="item in sysUser" :key="item.userId" :label="item.realName" :value="item.userId">
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item>
        <el-button @click="getDataList()" type="primary">搜索</el-button>
        <el-button @click="refresh()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button @click="addOrUpdateIpHandle(0)" type="primary">新增IP干预</el-button>
    <el-button @click="addOrUpdateRoleHandle(0)" type="primary">新增角色干预</el-button>
    <el-button @click="exportList()" type="primary">导出</el-button>
    <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
      style="width: 100%">
      <el-table-column prop="id" width="200px" header-align="center" align="center" label="任务ID">
      </el-table-column>

      <el-table-column prop="type" header-align="center" align="center" width="100px" label="干预类型">
        <template slot-scope="scope">
          <div>
            <span v-if="scope.row.type === 1">IP干预</span>
            <span v-else-if="scope.row.type === 2">角色干预</span>
          </div>
        </template>
      </el-table-column>


      <el-table-column header-align="center" align="center" width="150px" label="干预IP" prop="virtualIpDescription">
      </el-table-column>

      <el-table-column header-align="center" align="center" width="150px" label="干预角色" prop="virtualNickname">
      </el-table-column>


      <el-table-column prop="categoryDescription" width="100px" header-align="center" align="center" label="生效展位">
      </el-table-column>


      <el-table-column width="100px" prop="status" header-align="center" align="center" label="任务状态">
        <template slot-scope="scope">
          <div>
            <span v-if="scope.row.status === 0">未开始</span>
            <span v-else-if="scope.row.status === 1">进行中</span>
            <span v-else-if="scope.row.status === 2">已完成</span>
            <span v-else-if="scope.row.status === -1">已终止</span>
            <span v-else>未知</span>
          </div>
        </template>
      </el-table-column>


      <el-table-column prop="forceIndex" width="200px" header-align="center" align="center" label="强插位置">
      </el-table-column>

      <el-table-column header-align="center" width="200px" align="center" label="强插时间">
        <template slot-scope="scope">
          <div>
            <span>{{ scope.row.indexEffectiveStartTime }} <br>~<br> {{ scope.row.indexEffectiveEndTime }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="updatePersonName" header-align="center" align="center" label="配置人">
      </el-table-column>

      <el-table-column prop="updateTime" width="200px" header-align="center" align="center" label="配置时间">
      </el-table-column>


      <el-table-column fixed="right" header-align="center" align="center" width="100" label="操作">
        <template slot-scope="scope">
          <el-button v-if="scope.row.type === 1 && (scope.row.status === 0 || scope.row.status === 1)" type="text"
            size="small" @click="addOrUpdateIpHandle(scope.row.id)">配置编辑
          </el-button>
          <el-button v-if="scope.row.type === 2 && (scope.row.status === 0 || scope.row.status === 1)" type="text"
            size="small" @click="addOrUpdateRoleHandle(scope.row.id)">配置编辑
          </el-button>
          <el-button v-if="scope.row.status === 0 || scope.row.status === 1" type="text" size="small"
            style="color: rgba(236, 128, 141, 1)" @click="stop(scope.row.id)">任务中止
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update-ip v-if="addOrUpdateIpVisible" ref="addOrUpdateIp" @refreshDataList="getDataList"></add-or-update-ip>
    <add-or-update-role v-if="addOrUpdateRoleVisible" ref="addOrUpdateRole"
      @refreshDataList="getDataList"></add-or-update-role>
  </div>
</template>

<script>
import AddOrUpdateIp from "./intervention-ip-add-or-update";
import AddOrUpdateRole from "./intervention-role-add-or-update";
import { pickerOptions } from "@/views/common/commonOpt";

const sourceDataForm = {
  types: [],
  categoryIds: [],
  statusList: [],
  updatePersons: [],
  createTimeArr: [],
}

export default {
  data() {
    return {
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      sysUser: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      allCategoryList: [],
      typeOpt: [
        {
          value: 1,
          label: 'IP干预'
        },
        {
          value: 2,
          label: '角色干预'
        }
      ],
      statusOpt: [
        {
          value: 0,
          label: '未开始'
        },
        {
          value: 1,
          label: '进行中'
        },
        {
          value: 2,
          label: '已完成'
        },
        {
          value: -1,
          label: '已终止'
        }
      ],
      forceIndexTypeOpt: [
        {
          value: 0,
          label: '永久'
        },
        {
          value: 1,
          label: '有效时间'
        }
      ],
      pickerOptions: pickerOptions,
      addOrUpdateIpVisible: false,
      addOrUpdateRoleVisible: false
    }
  },
  components: {
    AddOrUpdateIp,
    AddOrUpdateRole

  },
  activated() {
    this.getDataList()
  },
  async created() {
    this.sysUser = await this.$store.dispatch("GetSysUser", {});
    this.getAllCategory()
  },
  methods: {
    refresh() {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.pageIndex = 1
      this.pageSize = 10
      this.getDataList()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/showIntervene/page'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          updateTimeStart: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
          updateTimeEnd: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
          page: this.pageIndex,
          limit: this.pageSize,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateIpHandle(id) {
      this.addOrUpdateIpVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdateIp.init(id)
      })
    },
    addOrUpdateRoleHandle(id) {
      this.addOrUpdateRoleVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdateRole.init(id)
      })
    },
    stop(id) {
      this.$confirm('当前任务进行中，是否提前中止？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/admin/showIntervene/stop`),
          method: 'post',
          data: this.$http.adornData({
            id: id
          })
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.getDataList()
            this.$message({
              message: '终止成功',
              type: 'success',
              duration: 1000
            })
            this.getDataList()
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    getAllCategory() {
      // 获取所有类目
      this.$http({
        url: this.$http.adornUrl(`/admin/virtualIp/allCategorySimple`),
        method: 'get'
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.allCategoryList = data.result
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    exportList() {
      const exportData = {
        ...this.dataForm,
        updateTimeStart: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
        updateTimeEnd: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
      }
      const paramJson = encodeURI(JSON.stringify(exportData).replace(/\+/g, "%2b"))
      window.open(
        this.$http.adornUrl('/admin/showIntervene/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`,
        "_blank"
      );
    }
  },
}
</script>