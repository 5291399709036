<template>
  <el-dialog
      width="50%"
      :title="!dataForm.id ? '新增IP干预' : '编辑IP干预'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm"
             ref="dataForm"
             :rules="dataRule"
             @keyup.enter.native="beforeSubmit()"
             label-width="120px">


      <el-form-item label="IP名称：" prop="virtualIpId">
        <el-select
            v-model="dataForm.virtualIpId"
            clearable
            placeholder="IP名称"
            @change="refreshCategory(dataForm.virtualIpId)"
        >
          <el-option
              v-for="item in allVirtualIpList"
              :key="item.id"
              :label="item.description"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="生效展位：" prop="categoryId">
        <el-select
            v-model="dataForm.categoryId"
            clearable
            placeholder="生效展位"
            @change="checkEffectiveTime"
        >
          <el-option
              v-for="item in allCategoryList"
              :key="item.id"
              :label="item.description"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="强插位置：" prop="forceIndex">
        <el-input v-model="dataForm.forceIndex" placeholder="强插位置"></el-input>
      </el-form-item>

      <el-form-item label="强插有效期：" prop="effectiveTimeArr">
        <el-date-picker
            v-model="dataForm.effectiveTimeArr"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="checkEffectiveTime"
            align="right">
        </el-date-picker>
      </el-form-item>

      <span v-if="this.errTips && this.errTips !== '' " style="color: red">错误：{{ errTips }}</span>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="beforeSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>

const sourceDataForm = {
  id: 0,
  type: 1,
  virtualIpId: '',
  status: 0,
  virtualUid: '',
  categoryId: '',
  indexEffectiveStartTime: '',
  indexEffectiveEndTime: '',
  cornerMark: 'None',
  forceIndex: '',
  addPopularityValue: '',
  allVirtualIpList: [],
  effectiveTimeArr: [],
}
export default {
  data() {
    return {
      errTips: '',
      visible: false,
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      dataRule: {
        'virtualIpId': [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        'categoryId': [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        'forceIndex': [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        'effectiveTimeArr': [
          {required: true, message: '不能为空', trigger: 'blur'}
        ]
      },
      allVirtualIpList: [],
      allCategoryList: [],
      cornerMarkOpt: [
        {
          value: 'New',
          label: 'New'
        },
        {
          value: 'None',
          label: 'None'
        }
      ],
    }
  },
  created() {
    this.getAllVirtualIp()
  },
  methods: {
    init(id) {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.errTips = ''
      this.dataForm.id = id || 0
      if (this.dataForm.id === 0) {
        // 新增模式
        this.visible = true
        return;
      }
      this.$nextTick(() => {
        if (this.$refs['dataForm']) this.$refs['dataForm'].resetFields()
      })
      this.$http({
        url: this.$http.adornUrl(`/admin/showIntervene/detail`),
        method: 'get',
        params: this.$http.adornParams({id: this.dataForm.id})
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.visible = true

          this.dataForm.id = data.result.id
          this.dataForm.status = data.result.status
          this.dataForm.type = data.result.type
          this.dataForm.virtualIpId = data.result.virtualIpId
          this.dataForm.virtualUid = data.result.virtualUid
          this.dataForm.categoryId = data.result.categoryId
          this.dataForm.forceIndex = data.result.forceIndex
          this.dataForm.indexEffectiveStartTime = data.result.indexEffectiveStartTime
          this.dataForm.indexEffectiveEndTime = data.result.indexEffectiveEndTime
          this.dataForm.addPopularityValue = data.result.addPopularityValue
          this.dataForm.cornerMark = data.result.cornerMark
          const timeArr = []
          timeArr[0] = data.result.indexEffectiveStartTime
          timeArr[1] = data.result.indexEffectiveEndTime
          this.dataForm.effectiveTimeArr = timeArr
          this.initCategory(data.result.virtualIpId)
        }
      })
    },
    beforeSubmit() {
      if (this.dataForm.id) {
        this.$confirm('当前配置信息是否已确认无误？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dataFormSubmit();
        })
      } else {
        this.dataFormSubmit();
      }
    },
    // 表单提交
    dataFormSubmit() {

      this.dataForm.indexEffectiveStartTime = (this.dataForm.effectiveTimeArr && this.dataForm.effectiveTimeArr.length > 0) ? this.dataForm.effectiveTimeArr[0] : null;
      this.dataForm.indexEffectiveEndTime = (this.dataForm.effectiveTimeArr && this.dataForm.effectiveTimeArr.length > 0) ? this.dataForm.effectiveTimeArr[1] : null;

      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/admin/showIntervene/${!this.dataForm.id ? 'add' : 'update'}`),
            method: 'post',
            data: this.$http.adornData({
              ...this.dataForm
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    },
    async getAllVirtualIp() {
      // 获取所有ip
      await this.$http({
        url: this.$http.adornUrl(`/admin/virtualIp/all`),
        method: 'get'
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.allVirtualIpList = data.result
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    initCategory(virtualIpId) {
      // 获取所有类目
      this.$http({
        url: this.$http.adornUrl(`/admin/virtualIp/queryByVirtualIpId?virtualIpId=${virtualIpId}`),
        method: 'get'
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.allCategoryList = data.result
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    refreshCategory(virtualIpId) {
      this.initCategory(virtualIpId)
      this.checkEffectiveTime()
    },
    checkEffectiveTime(){
      this.dataForm.indexEffectiveStartTime = (this.dataForm.effectiveTimeArr && this.dataForm.effectiveTimeArr.length > 0) ? this.dataForm.effectiveTimeArr[0] : null;
      this.dataForm.indexEffectiveEndTime = (this.dataForm.effectiveTimeArr && this.dataForm.effectiveTimeArr.length > 0) ? this.dataForm.effectiveTimeArr[1] : null;
      this.$http({
        url: this.$http.adornUrl(`/admin/showIntervene/checkEffectiveTime`),
        method: 'post',
        data: this.dataForm
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.errTips = ''
        } else {
          this.errTips = data.msg
        }
      })
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>